import { shaderMaterial } from "@react-three/drei";
import { extend } from "@react-three/fiber";

import { vertexShader } from "./shaders/vertex";
import { fragmentShader } from "./shaders/fragment";

const ImageDepthMapMaterial = shaderMaterial(
  {
    image0: undefined,
    image1: undefined,
    threshold: { x: 100.0, y: 100.0 },
    mouse: { x: 0, y: 0 },
    opacity: 1,
  },
  vertexShader,
  fragmentShader
);

ImageDepthMapMaterial.toneMapped = false;
extend({ ImageDepthMapMaterial });
