import * as THREE from "three";
import { useRef, useState, useEffect } from "react";
import { useLoader, useFrame, useThree } from "@react-three/fiber";
import { useAspect } from "@react-three/drei";
import "./ImageDepthMapMaterial";

const useTextures = (urls) => {
  const textures = useLoader(THREE.TextureLoader, urls);
  textures.forEach((t) => {
    // Necessary to get correct colors with sRGB outputEncoding!
    t.encoding = THREE.sRGBEncoding;
  });
  return textures;
};

export default function ImageDepthMap(props) {
  const ref = useRef();
  const [texture1, texture2] = useTextures([props.originalImg, props.depthImg]);

  const { size } = useThree();
  // Mobile
  const mobile = size.width < 768 ? true : false;

  const scale = useAspect(
    mobile ? 900 : 1920, // Pixel-width
    1080, // Pixel-height
    1 // Optional scaling factor
  );

  const [mouseTargetX, setMouseTargetX] = useState(0);
  const [mouseTargetY, setMouseTargetY] = useState(0);
  const [mouseX, setMouseX] = useState(0);
  const [mouseY, setMouseY] = useState(0);

  //
  useEffect(() => {
    const setFromEvent = (e) => {
      const windowWidth = window.innerWidth;
      const windowHeight = window.innerHeight;
      let halfX = windowWidth / 2;
      let halfY = windowHeight / 2;

      setMouseTargetX((halfX - e.clientX) / halfX);
      setMouseTargetY((halfY - e.clientY) / halfY);
    };
    window.addEventListener("mousemove", setFromEvent);

    return () => {
      window.removeEventListener("mousemove", setFromEvent);
    };
  }, []);

  // useFrame
  useFrame(() => {
    setMouseX(mouseX + (mouseTargetX - mouseX) * 0.05);
    setMouseY(mouseY + (mouseTargetY - mouseY) * 0.05);
    if (ref.current) {
      ref.current.mouse = { x: mouseX, y: -mouseY };
    }
  });

  // Return
  return (
    <mesh scale={scale}>
      <planeBufferGeometry attach="geometry" />

      <imageDepthMapMaterial
        ref={ref}
        attach="material"
        image0={texture1}
        image1={texture2}
        opacity={props.opacity}
        blending={THREE.AdditiveBlending}
      />
      {/* <meshStandardMaterial toneMapped={true} map={texture1} /> */}
    </mesh>
  );
}
