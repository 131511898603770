import React from "react";

import Particles from "./components/Particles";
import SmokeFixed from "./components/SmokeFixed";

function Home() {
  return (
    <>
      <Particles count={2000} position-z={0} />
      <SmokeFixed />
    </>
  );
}

export default Home;
