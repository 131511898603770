import React, { useRef } from "react";
import { useThree, useLoader } from "@react-three/fiber";
import * as THREE from "three";

import Particles from "./components/Particles";
import SmokeHero from "./components/SmokeHero";
import { ImageDepthMap } from "./components/ImageDepthMap";

import { TextureLoader } from "three/src/loaders/TextureLoader";

function Hero() {
  const mesh = useRef();
  const { size } = useThree();

  const mobile = size.width < 768 ? true : false;
  const colorMap = useLoader(TextureLoader, "./senua-hero-bg-new.jpg");
  colorMap.encoding = THREE.sRGBEncoding;

  return (
    <>
      <group ref={mesh} position-z={0}>
        {/* <mesh position-x={-5}>
          <planeGeometry args={[16, 9]} />
          <meshStandardMaterial
            map={colorMap}
            blending={THREE.AdditiveBlending}
            toneMapped={false}
          />
        </mesh> */}

        {!mobile && (
          <ImageDepthMap
            originalImg={"./senua-hero-bg-new.jpg"}
            depthImg={"./senua-depthmap.jpg"}
            verticalThreshold={25}
            horizontalThreshold={15}
            opacity={1.0}
          ></ImageDepthMap>
        )}

        {mobile && (
          <ImageDepthMap
            originalImg={"./senua-hero-bg-mobile.jpg"}
            depthImg={"./senua-depthmap-mobile.jpg"}
            verticalThreshold={25}
            horizontalThreshold={15}
            opacity={1.0}
          ></ImageDepthMap>
        )}
      </group>
      <Particles count={500} position-z={-0.5} />
      <SmokeHero />
    </>
  );
}

export default Hero;
