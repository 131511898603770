import * as THREE from "three";
import React, { useRef, useMemo } from "react";
import { useFrame, useLoader, useThree } from "@react-three/fiber";

const SmokeParticle = (props) => {
  const { position, texture, scale, opacity, speed } = props;
  const smoke = useRef();
  useFrame(() => {
    if (smoke.current) {
      smoke.current.rotation.z += 0.0003 * speed;
    }
  });
  return (
    <mesh ref={smoke} position={position} scale={[scale, scale, 0]}>
      <planeBufferGeometry args={[1, 1]} />
      <meshBasicMaterial
        depthTest={false}
        opacity={opacity}
        map={texture}
        transparent={true}
        toneMapped={false}
      />
    </mesh>
  );
};

const SmokeHero = ({ state }) => {
  const [smokeTexture1, smokeTexture2] = useLoader(THREE.TextureLoader, [
    "/smoke-1.png",
    "/smoke-2.png",
  ]);

  smokeTexture1.encoding = THREE.sRGBEncoding;
  const { viewport } = useThree();

  const mesh = useRef();

  const scale = 1;

  const positions = useMemo(
    () => [
      {
        x: viewport.width / 2 - 2,
        y: 0,
        scale: 20,
        opacity: 0.8,
        speed: 1,
        texture: smokeTexture1,
      },
      {
        x: viewport.width / 1.5,
        y: -viewport.height / 2,
        scale: 30,
        opacity: 0.5,
        speed: 1.3,
        texture: smokeTexture2,
      },
      {
        x: -viewport.width / 2,
        y: viewport.height / 9,
        scale: 20,
        opacity: 0.5,
        speed: -0.5,
        texture: smokeTexture2,
      },

      {
        x: -viewport.width / 3,
        y: -viewport.height / 2,
        scale: 15,
        opacity: 0.5,
        speed: -0.75,
        texture: smokeTexture1,
      },
    ],
    [viewport.width, viewport.height, smokeTexture1, smokeTexture2]
  );

  const lines = useMemo(
    () =>
      positions.map((item, index) => {
        return {
          position: [
            // randomBetween(viewport.width / 2),
            // randomBetween(viewport.height / 2),
            item.x,
            item.y,
            1,
          ],
          rotation: [0, 0, Math.random() * 360],
          color: "red",
          index: index,
          texture: item.texture,
          scale: item.scale,
          opacity: item.opacity,
          speed: item.speed,
        };
      }),
    [positions]
  );

  return (
    <group position={[0, 0, -1]} scale={[scale, scale, scale]}>
      <group ref={mesh}>
        {lines.map((props, index) => (
          <SmokeParticle key={index} {...props} />
        ))}
      </group>
    </group>
  );
};
export default SmokeHero;
