export const fragmentShader = `varying vec2 vUv;

// 3d args
uniform vec2 mouse;
uniform vec2 threshold;
uniform float opacity;

uniform sampler2D image0;
uniform sampler2D image1;

vec2 mirrored(vec2 v) {
    vec2 m = mod(v,2.);
    return mix(m,2.0 - m, step(1.0 ,m));
  }

void main() {
    vec2 uv = vUv;
    vec4 tex1 = texture2D(image1,mirrored(vUv));
    vec2 fake3d = vec2(vUv.x + (tex1.r - 0.5)*mouse.x/threshold.x, vUv.y + (tex1.r - 0.5)*mouse.y/threshold.y);
    vec4 final = vec4(texture2D(image0,mirrored(fake3d)));
    final.a = opacity;
    gl_FragColor = final;
    #include <tonemapping_fragment>
    #include <encodings_fragment>
}

`;
